<template>
  <div class="footer">
    © {{ currentYear }} YAKUMAN TECH LTD  All Rights Reserved.
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }

}
</script>

<style scoped>
.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 90px;
  background: #3a416b;

  font-weight: 400;
  font-size: 14px;
  color: #FFFFFF;
}
</style>

<template>
  <div class="content">
    <img src="../assets/picture.jpg" alt="" srcset="">
    <div class="text-block">
      <div class="title">
        Who We Are
      </div>
      <div class="text">
        YAKUMAN TECH LTD is an innovative and forward-thinking game development company with a passion for creating high-quality, immersive, and engaging gaming experiences. Established in 2023, the company has quickly gained a reputation for its cutting-edge technology and artistic prowess in the gaming industry.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheContent',
}
</script>

<style lang="scss" scoped>
.content {  
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 180px 80px;

  @media only screen and (max-width: 1300px) {
    flex-direction: column;
    padding: 140px 20px;
  }

  .text-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-left: 30px;

    @media only screen and (max-width: 1300px) {
      width: 100%;
      margin: 0;
    }
  }
  .title {
    width: 100%;
    text-align: left;
    font-weight: 700;
    font-size: 38px;
    line-height: 1.42;
    color: #404040;
    margin-bottom: 25px;

    @media only screen and (min-width: 751px) and (max-width: 1300px) {
      margin-top: 80px;
      font-size: 80px;
    }

    @media only screen and (max-width: 750px) {
      margin-top: 40px;
      font-size: 40px;    }
  }

  .text {
    text-align: left;
    max-width: 500px;
    font-weight: 400;
    font-size: 22px;
    line-height: 1.76;
    letter-spacing: 0.445833px;
    color: #6b6b6b;

    @media only screen and (max-width: 750px) {
      max-width: 100%;
    }

  }

  img {
    width: 100%;
    max-width: 500px;
  }
}
</style>
